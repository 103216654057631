:root {
  --accent: red;
  --background: blue;
  --ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  --ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  --ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  --ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  --ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);

  --ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
  --ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
  --ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
  --ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
  --ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);

  --ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  --ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
  --ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
  --ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
  --ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
  --ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.dark-mode-icon-wrapper[data-a11y='true']:focus::after {
  content: '';
  position: absolute;
  left: 0;
  top: -30%;
  width: 100%;
  height: 160%;
  border: 2px solid var(--accent);
  background: rgba(255, 255, 255, 0.01);
  border-radius: 5px;
}
.dark-mode-icon-wrapper:hover {
  opacity: 1;
}
.dark-mode-icon-wrapper {
  opacity: 0.5;
  position: relative;
  border-radius: 5px;
  width: 40px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  margin-left: 30px;
}

/* Thanks for this great demo! https://codepen.io/aaroniker/pen/KGpXZo */
/*bg-white dark:bg-gray-800*/
.moon-or-sun.dark::after {
  transform: scale(1);
}
.moon-or-sun.dark::before {
  transform: translate(14px, -14px);
  opacity: 0;
}
.moon-or-sun.dark {
  transform: scale(0.55);
  border: 4px solid theme('colors.white');
  background: theme('colors.white');
  overflow: visible;
}

.moon-or-sun::before {
  content: '';
  position: absolute;
  right: -9px;
  top: -9px;
  height: 24px;
  width: 24px;
  border: 2px solid theme('colors.gray.800'); /**/
  border-radius: 50%;
  transform: translate(0, 0);
  opacity: 1;
  transition: transform 0.45s ease;
}

.moon-or-sun::after {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: -4px 0 0 -4px;
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: 0 -23px 0 theme('colors.white'), 0 23px 0 theme('colors.white'), 23px 0 0 theme('colors.white'),
    -23px 0 0 theme('colors.white'), 15px 15px 0 theme('colors.white'), -15px 15px 0 theme('colors.white'),
    15px -15px 0 theme('colors.white'), -15px -15px 0 theme('colors.white');
  transform: scale(0);
  transition: all 0.35s ease;
}

.moon-or-sun {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid theme('colors.gray.800');
  background: theme('colors.gray.800');
  transform: scale(1);
  transition: all 0.45s ease;
  overflow: hidden;
}

.moon-mask.dark {
  transform: translate(14px, -14px);
  opacity: 0;
  background: theme('colors.gray.800');
}

.moon-mask {
  position: absolute;
  right: -1px;
  top: -8px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: theme('colors.white');
  border: 0;
  /*background: var(--bg-gray-800);*/
  /*@apply bg-gray-800;*/
  transform: translate(0, 0);
  opacity: 1;
  transition: background 0.25s var(--ease-in-out-quad), color 0.25s var(--ease-in-out-quad), transform 0.45s ease;
}

/*
const Hidden = styled.span`
  position: absolute;
  display: inline-block;
  opacity: 0;
  width: 0px;
  height: 0px;
  visibility: hidden;
  overflow: hidden;
`;
*/

/* 
:root {
  --sol-blue: red;
}

.bg-sol-blue {
  background-color: red;
}

.border-sol-blue {
  border-width: 10px;
}

.iconWrapper {
  @apply relative inline-flex items-center justify-center w-10 h-10 p-0
  overflow-hidden align-middle bg-transparent border-0 rounded opacity-75
  appearance-none cursor-pointer;
  /*
    roughly compensates for the additional whitespace of this specific
    "icon button" in relation to its "social icon" siblings;
    leave the left untouched for some separation from the aforementioned
  */
margin-right: -11px;
/*
    scaling to 75% of the original size
    scales the "moon" shape from 24px to 18px,
    aligning it with the rest of the main nav's
    "social icons"; sun and its rays are slightly larger;
    good for now, too ;-)
  * /
  transform: scale(0.75);
  transition: opacity 0.3s ease;
}
.iconWrapper:hover {
  opacity: 1;
}

.moonOrSun {
  @apply relative w-6 h-6 overflow-hidden scale-100 border-2 rounded-full
  bg-sol-blue border-sol-blue;
  transition: all 0.45s ease;
}
.themeDark .moonOrSun {
  @apply overflow-visible border-4 border-sol-blue;
  transform: scale(0.55);
}
.moonOrSun::before {
  @apply absolute w-6 h-6 border-2 rounded-full opacity-100 border-sol-blue;
  content: '';
  right: -9px;
  top: -9px;
  transition: transform 0.45s ease;
  transform: translate(0, 0);
}
.themeDark .moonOrSun::before {
  opacity: 0;
  transform: translate(14px, -14px);
}
.moonOrSun::after {
  @apply absolute w-2 h-2 m-0 -mt-1 -ml-1 rounded-full;
  transform: scale(0);
  box-shadow: 0 -23px 0 var(--sol-blue), 0 23px 0 var(--sol-blue), 23px 0 0 var(--sol-blue), -23px 0 0 var(--sol-blue),
    15px 15px 0 var(--sol-blue), -15px 15px 0 var(--sol-blue), 15px -15px 0 var(--sol-blue),
    -15px -15px 0 var(--sol-blue);
  content: '';
  left: 50%;
  top: 50%;
  transition: all 0.35s ease;
}
.themeDark .moonOrSun::after {
  transform: scale(1);
}
.moonMask {
  @apply absolute top-0 right-0 w-6 h-6 bg-white border-0 rounded-full opacity-100;
  transition: background 0.25s ease, transform 0.45s ease;
  transform: translate(0, 0);
}
.themeDark .moonMask {
  opacity: 0;
  transform: translate(14px, -14px);
}
*/
